.update-projects-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 35px;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    h3 {
        margin-top: unset;
    }
    .left-content, .right-content {
        display: flex;
        width: 47.5%;
    }
    .left-content {
        position: relative;
        flex-direction: column;
        .switch-label {
            position: absolute;
            top: 0px;
            right: 45px;
        }
        .switch {
            position: absolute;
            top: 0px;
            right: 0px;
            display: inline-block;
            width: 40px;
            height: 20px;
            input {
                opacity: 0;
                width: 0;
                height: 0;
            }
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: .4s;
                transition: .4s;
                &:before {
                    position: absolute;
                    content: "";
                    height: 15px;
                    width: 15px;
                    left: 2.75px;
                    bottom: 2.75px;
                    background-color: white;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
            }
            .slider.round {
                border-radius: 34px;
                &:before {
                    border-radius: 50%;
                }
            }
            .slider.disabled {
                cursor: not-allowed;
            }
            input:checked + .slider {
                background-color: $color-primary;
            }
            input:focus + .slider {
                box-shadow: 0 0 1px $color-primary;
            }
            input:checked + .slider:before {
                -webkit-transform: translateX(20px);
                -ms-transform: translateX(20px);
                transform: translateX(20px);
            }
        }
        .field {
            display: flex;
            flex-direction: column;
            input {
                position: relative;
                height: 50px;
                margin: 20px 0px;
                padding-left: 20px;
                border: none;
                border-radius: 8px;
                background-color: #EFF1F999;
                font-size: 16px;
                &::placeholder {
                    position: absolute;
                    left: 20px;
                    color: #ABAFB1;
                }
                &:disabled {
                    cursor: not-allowed;
                }
            }
        }
        .basic-multi-select, .basic-single {
            pointer-events: unset;
            .css-13cymwt-control, .css-16xfy0z-control, .select__control--is-focused {
                height: 50px;
                margin: 20px 0px;
                border: none;
                border-radius: 8px;
                background-color: #EFF1F999;
                font-size: 16px;
                .css-1fdsijx-ValueContainer {
                    padding: 2px 8px 2px 20px;
                }
            }
            .css-13cymwt-control, .select__control--is-focused {
                cursor: pointer;
            }
            .css-16xfy0z-control {
                cursor: not-allowed;
            }
            .select__control .css-3w2yfm-ValueContainer {
                height: 100%;
                overflow: scroll;
                flex-wrap: nowrap;
                .css-1p3m7a8-multiValue {
                    min-width: unset;
                }
            }
            .css-1nmdiq5-menu {
                top: 80%;
                .select__option {
                    cursor: pointer;
                }
            }
        }
        .basic-multi-select .css-wsp0cs-MultiValueGeneric {
            padding-right: 6px;
        }
        .mandatory {
            color: #83898C;
        }
    }
    .right-content {
        flex-direction: column;
        video {
            border-radius: 5px;
        }
    }
    .buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        .cancel-btn, .create-project-btn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 225px;
            min-height: 48px;
            border: none;
            border-radius: 12px;
            margin: 40px 10px 10px 10px;
        }
        .cancel-btn {
            color: $color-primary;
            border: solid 1px $color-primary;
            background-color: white;
        }
        .create-project-btn {
            color: white;
            background-color: $color-primary;
        }
    }
}