.MIN-NW-LEFT-NAV {
    width: 7.5%;
    max-width: 125px;
    .nw-logo {
        width: 41px;
        min-height: 46px;
    }
    .nav-action-btn {
        transform: rotate(180deg);
    }
    .line {
        width: 60%;
    }
    .second-line {
        width: 75%;
    }
    nav ul li {
        max-width: 75px;
        a {
            display: flex;
            img {
                margin: auto!important;
            }
            .nolej-logo {
                width: 50px;
            }
        }
        span {
            max-width: 0px;
            overflow: hidden;
        }
    }
}
.NW-LEFT-NAV {
    transition: all 0.2s ease-in-out;
    width: 25%;
    max-width: 315px;
    @include level2 {
        width: 20%;
    }
    .nw-logo {
        width: 80%;
        min-height: 46px;
    }
    .line {
        width: 80%;
    }
    .second-line {
        width: 100%;
    }
    nav ul {
        li a {
            width: 100%;
            img {
                margin: auto 10px;
            }
            span {
                max-width: unset;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        .nolej-btn a {
            width: fit-content;
            margin: unset;
            .nolej-logo {
                height: 45px;
                margin-right: unset;
            }
        }
    }
}
.NW-LEFT-NAV, .MIN-NW-LEFT-NAV {
    position: fixed;
    z-index: 98;
    top: 0px;
    left: 0px;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    &:hover > .nav-action-btn {
        opacity: 1;
    }
    .nw-logo {
        margin: 15px auto;
    }
    .nolej-logo {
        margin-left: unset;
    }
    .nav-action-btn {
        transition: opacity 0.2s;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 26px;
        right: -10px;
        width: 24px;
        height: 24px;
        &:hover {
            background-color: $color-secondary;
            border-radius: 4px;
        }
    }
    .line, .second-line {
        background-color: #BBC5CB;
        height: 0.5px;
        margin: 0px auto;
    }
    .second-line {
        display: flex;
    }
    nav {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        ul {
            width: 80%;
            list-style: none;
            padding: unset;
            li {
                margin: 10px auto;
                a {
                    cursor: pointer;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 100%;
                    min-height: 40px;
                    max-height: 35px;
                    margin: auto 0px;
                    padding: 2.5px 0px;
                    border-radius: 10px;
                    text-decoration: none;
                    color: $color-quaternary;
                }
            }
            .active {
                background-color: $color-primary;
                color: white;
            }
        }
        .top-nav {
            background-color: white;
            margin: 15px auto;
            .navlink {
                transition: background-color 0.2s;
                &:hover {
                    background-color: rgba(94, 99, 102, 0.1019607843);
                    color: #000000;
                    img {
                        filter: unset;
                    }
                }
                &:focus, .active {
                    background-color: $color-primary;
                    color: white;
                }
            }
            .navlink:focus > img, .active > img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
            }
        }
        .bottom-nav {
            margin: 30px auto;
            .help, .credit {
                margin: 10px auto;
                padding: 2.5px 0px;
                border-radius: 10px;
            }
            .help {
                background-color: #5E63661A;
            }
            .credit {
                background-color: #FFCC9133;
            }
        }
    }
}