.project-tag {
    display: flex;
    justify-content: center;
    padding: 2.5px 10px;
    min-height: 26px;
    line-height: 26px;
    border-radius: 8px;
}
.project-tag.finished {
    color: #519C66;
    background-color: #32936F29;
}
.project-tag.upload, .project-tag.lexicons {
    color: #C68B44;
    background-color: #FFF2E2;
}
.project-tag.transcription, .project-tag.translation {
    color: #9C42D3;
    background-color: #F6E2FF;
}
.project-tag.render, .project-tag.archive {
    color: #5570F1;
    background-color: #5570F114;
}
.project-tag.delete {
    color: #CC5F5F;
    background-color: #F57E7729;
}
.project-tag.public, .project-tag.private {
    background-color: white;
}
.project-tag.public {
    color: #519C66;
    border: solid 1px #519C66;
}
.project-tag.private {
    color: #5E6366;
    border: solid 1px #5E6366;
}