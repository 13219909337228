@keyframes rotateAnimation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1turn);
    }
}
.project-card {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-shrink: 1;
    width: 100%;
    height: 62px;
    margin: 10px auto;
    background-color: white;
    border-radius: 10px;
    transition: background-color 0.2s;
    &:hover {
        background-color: rgba(94, 99, 102, 0.1019607843);
    }
    .tag {
        width: 15%;
        max-width: 100px;
        margin: auto;
    }
    .file, .profil-picture {
        width: 24px;
        height: 24px;
        margin: auto 10px;
    }
    p {
        margin: auto;
    }
    .file, .name {
        margin: auto 5px auto 10px;
    }
    .name {
        width: 15%;
        color: $color-quaternary;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @include level2 {
            width: 30%;
        }
    }
    .profile-picture, .username {
        margin: auto 5px;
    }
    .profile-picture {
        width: 40px;
        height: 40px;
        border-radius: 90%;
    }
    .username {
        max-width: 10%;
        width: 10%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .actions {
        display: flex;
        justify-content: flex-end;
        width: 10%;
        .run, .download, .delete, .restore {
            cursor: pointer;
            width: 24px;
            height: 24px;
            margin: auto 5px;
            @include level2 {
                margin: auto 10px;
            }
        }
        .run {
            animation: rotateAnimation linear 2s infinite;
        }
    }
}