.signin-form {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 750px;
    margin: auto;
    padding: 40px;
    background-color: white;
    border-radius: 14px;
    .previous-btn {
        position: absolute;
        cursor: pointer;
        display: flex;
        width: 40px;
        height: 40px;
        border: solid 1px $color-primary;
        border-radius: 90%;
        background-color: white;
        img {
            margin: auto;
        }
    }
    h2 {
        margin: 20px auto;
        font-size: 30px;
        text-align: center;
    }
    .error-message {
        display: flex;
        width: 90%;
        max-width: 540px;
        margin: 20px auto;
        color: red;
    }
    .field {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 90%;
        max-width: 540px;
        margin: 0px auto;
        input {
            position: relative;
            height: 50px;
            margin: 10px 0px 20px 0px;
            padding-left: 20px;
            border: none;
            border-radius: 8px;
            background-color: #EFF1F999;
            font-size: 16px;
            &::placeholder {
                position: absolute;
                left: 20px;
                color: #ABAFB1;
            }
        }
        span {
            cursor: pointer;
            text-decoration: underline;
        }
        .visibility-icon {
            cursor: pointer;
            position: absolute;
            top: 45px;
            right: 20px;
            width: 25px;
            height: 25px;
        }
    }
    button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        max-width: 540px;
        height: 50px;
        border: none;
        border-radius: 12px;
        color: white;
        background-color: $color-primary;
        margin: 20px auto;
        font-size: 16px;
    }
}