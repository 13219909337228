@keyframes rotateAnimation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1turn);
    }
}
.loading-modal {
    display: none;
    position: absolute;
    z-index: 96;
    width:100%;
    height: 100%;
    right: 0%;
    background-color: rgba(0,0,0,.643);
    backdrop-filter: blur(4px);
    .content {
        position: absolute;
        z-index: 96;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        display: flex;
        flex-direction: column;
        width: 40%;
        height: 33vh;
        margin: auto;
        border-radius: 12px;
        .icon {
            animation: rotateAnimation linear 2s infinite;
            width: 50px;
            height: 50px;
            margin: auto;
        }
        h3 {
            position: absolute;
            bottom: 15%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
        }
    }
}
.loading-modal.force-display {
    display: flex!important;
}