.WORKFLOW-LEXICON-02 {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    @include level2 {
        width: 80%;
    }
    h2 {
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        color: $color-primary;
    }
    .lexicons {
        width: 100%;
        margin: 20px auto;
    }
    .btn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px 20px auto;
        min-width: 225px;
        min-height: 48px;
        color: white;
        background-color: $color-primary;
        border: none;
        border-radius: 12px;
    }
}