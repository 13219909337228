.SUBPLAYER {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    height: 60vh;
    overflow: hidden;
    border-radius: 5px;
    background-color: black;
    .editor {
        height: 100%;
        &:fullscreen {
          .subtitle {
            bottom:5%;
          }
          .fullscreen img:first-child {
            display: none;
          }
          .fullscreen img + img {
            display: block;
          }
        }
    }
    .main {
        display: flex;
        justify-content: stretch;
        height: calc(100% - 112px);
        .subplayer-player {
            flex: 1;
        }
        .subplayer-tool {
            width: 300px;
        }
    }
    .fullscreen, .save {
        position:absolute;
        z-index:11;
        cursor:pointer;
        bottom:0;
        right:0;
        width:30px; 
        height:30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: $color-primary;
        border: none;
        border-radius: 12px;
        margin:10px;
    }
    .save { 
        right:40px;
    }
    .fullscreen img + img {
        display:none; 
    }
}
