.profile-form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 45%;
    margin-left: 5%;
    .field {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin: 10px auto;
        label {
            margin: 5px 0px;
            font-size: 12px;
        }
        input {
            position: relative;
            height: 52px;
            padding-left: 20px;
            background-color: #EFF1F999;
            border-radius: 8px;
            border: none;
            font-size: 16px;
            &::placeholder {
                position: absolute;
                left: 20px;
                color: #ABAFB1;
            }
        }
    }
    .firstname, .lastname {
        width: 49%;
    }
    .mail, .password {
        position: relative;
        img {
            position: absolute;
            top: 45px;
            right: 20px;
        }
    }
    button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 540px;
        height: 50px;
        border: none;
        border-radius: 12px;
        color: white;
        background-color: $color-primary;
        margin: 10px auto;
        font-size: 16px;
    }
}