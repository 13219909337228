.dropzone, .dropzone-disabled, .min-dropzone, .min-dropzone-disabled {
    display: flex;
    margin: auto;
    width: 100%;
    border: solid 1px red;
    border-radius: 12px;
    .content {
        width: 100%;
        display: flex;
        margin: auto 5%;
        .filename-title {
            color: black;
        }
        p {
            color: $color-primary;
            margin: auto;
            text-align: center;
        }
        span {
            text-align: center;
        }
    }
}
.dropzone, .dropzone-disabled {
    height: calc(100% - 40px);
    .content {
        flex-direction: column;
        img {
            margin: 20px auto;
            width: 75px;
            height: 75px;
        }
        p {
            font-size: 16px;
        }
    }
}
.min-dropzone, .min-dropzone-disabled {
    width: -webkit-fill-available;
    margin: 20px 0px;
    padding: 20px;
    .content {
        flex-direction: row;
        margin: auto;
        img {
            width: 50px;
            height: 50px;
        }
        p {
            font-size: 14px;
        }
        span {
            margin: auto;
        }
    }
}
.dropzone, .min-dropzone {
    cursor: pointer;
}
.dropzone-disabled, .min-dropzone-disabled {
    cursor: not-allowed;
}