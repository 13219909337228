.LEXICONS-MODAL {
    z-index: 98;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 550px;
    height: 250px;
    background-color: white;
    border-radius: 12px;
    h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 5% 0% 0% 14%;
    }
    .close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 18px;
        height: 18px;
    }
    .field {
        display: flex;
        #lexicon-name {
            position: relative;
            width: 90%;
            height: 50px;
            margin: 20px auto;
            border: none;
            border-radius: 8px;
            background-color: #EFF1F999;
            font-size: 16px;
            &::placeholder {
                position: absolute;
                left: 20px;
                color: #ABAFB1;
            }
    }
    }
}