@keyframes rotateAnimation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1turn);
    }
}
.PROJECTS-MODAL {
    z-index: 98;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 50%;
    min-height: 50vh;
    padding: 20px 45px;
    background-color: white;
    border-radius: 12px;
    .close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 20px;
        width: 18px;
        height: 18px;
    }
    .spinner-icon {
        animation: rotateAnimation linear 2s infinite;
        margin: auto;
        width: 75px;
        height: 75px;
    }
}