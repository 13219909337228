.confirmation-modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 98;
    width: 500px;
    height: 200px;
    background-color: white;
    border-radius: 12px;
    .content {
        margin: auto;
        width: 80%;
        p {
            font-size: 16px;
            width: 100%;
            margin: 20px auto;
            text-align: center;
        }
        .actions {
            display: flex;
            justify-content: center;
            button {
                cursor: pointer;
                width: 35%;
                height: 50px;
                margin: auto 20px;
                border-radius: 12px;
                border: none;
            }
            .cancel-btn {
                color: $color-primary;
                background-color: #FEF6F6;
            }
            .confirm-btn {
                color: white;
                background-color: $color-primary;
            }
        }
    }
}