.HELP-QUESTIONS {
    margin: 20px auto 15px auto;
    h2 {
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        color: #C30F0E;
    }
    .help-accordions {
        padding: unset;
    }
}