.PROJECTS-HEADER {
    position: relative;
    margin: 20px auto 0px auto;
    h2 {
        display: flex;
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        color: $color-primary;
        img {
            margin-right: 10px;
        }
    }
    p {
        color: $color-quaternary;
    }
}