.add-team-users-form {
    display: flex;
    input {
        position: relative;
        width: calc(75% - 5px);
        height: 50px;
        margin: 20px 0px;
        padding-left: 20px;
        border: none;
        border-radius: 8px;
        background-color: white;
        font-size: 16px;
        &::placeholder {
            position: absolute;
            left: 20px;
            color: #ABAFB1;
        }
    }
    button {
        position: relative;
        cursor: pointer;
        align-items: center;
        width: calc(20% - 5px);
        height: 50px;
        border: none;
        border-radius: 12px;
        color: white;
        background-color: $color-primary;
        margin: auto;
        padding: 1px unset;
        span {
            display: flex;
            margin: auto auto auto 10%;
            img {
                position: static;
                margin: auto 0%;
                height: 25px;
                width: 25px;
            }
            p {
                margin: unset;
                line-height: 25px;
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}