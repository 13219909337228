.subplayer-footer {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #454545;
    height: 112px;
    .progress {
        position: relative;
        z-index: 11;
        width: 100%;
        height: 12px;
        user-select: none;
        border-top: 1px solid rgb(255 255 255 / 20%);
        background-color: rgb(0 0 0 / 50%);
        .bar {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 0%;
            height: 100%;
            display: inline-block;
            background-color: #C30F0E;
            overflow: hidden;
            .handle {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 10px;
                cursor: ew-resize;
                background-color: #FFB1B0;
            }
        }
        .subtitle {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            span {
                position: absolute;
                top: 0;
                bottom: 0;
                height: 100%;
                background-color: rgb(255 255 255 / 20%);
            }
        }
    }
    .ruler {
        height: 20px;
        canvas {
            width: 100%;
            height: 100%;
        }
    }
    .waveform {
       flex: 1 1 auto;
     }
     .cursor {
        position: absolute;
        top: 12px;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: rgb(255 0 0 / 100%);
     }
     .timeline {
       flex: s1 1 auto;
     }
    .subplayer-actions {
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        left: 0;
        right: 0;
        top: -20px;
        z-index: 12;
        width: 100%;
        color: rgb(255 255 255 / 75%);
        background-color: #000000;
        text-shadow: 0 1px 2px rgb(0 0 0 / 75%);
        text-align: center;
        user-select: none;
        pointer-events:none;
        .fwd, .rwd, .play, .pause {
            display: flex;
            color: white;
            background-color: transparent;
            border: none;
            transition: opacity linear 0.3s;
            outline: none;
            pointer-events: auto;
            cursor: pointer;
            img {
                height: 16px;
            }
            &.hidden {
                opacity: 0.5;
                pointer-events: none;
            }
        }
        .play > img, .pause > img {
            height: 24px;
        }
        .current-timer, .total-timer {
            position: absolute;
            font-size: 12px;
        }
        .current-timer {
            left: 10px;
        }
        .total-timer {
            right: 10px;
        }
    }
    .BaseAudioContext {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        z-index: 1;
        user-select: none;
        pointer-events: none;
    }
    .grab {
        position: absolute;
        bottom: 0;
        z-index: 11;
        cursor: grab;
        height: 20px;
        width: 100%;
        user-select: none;
        background-color: rgb(33 150 243 / 20%);
        border-top: 1px solid rgb(33 150 243 / 30%);
        border-bottom: 1px solid rgb(33 150 243 / 30%);
        &.grabbing {
            cursor: grabbing;
        }
    }
}