.add-lexicon-btn {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 48px;
    min-width: 225px;
    min-height: 48px;
    margin: 10px 10px 10px 0px;
    color: white;
    background-color: $color-primary;
    border: none;
    border-radius: 12px;
    img {
        position: absolute;
        left: 10%;
        height: 25px;
        width: 25px;
    }
}