.HOME-CONTENT .add-project-btn {
    top: 0%;
    @include level2 {
        top: 5%;
    }
}
.add-project-btn {
    cursor: pointer;
    position: absolute;
    top: 10%;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 225px;
    min-height: 48px;
    color: white;
    background-color: $color-primary;
    border: none;
    border-radius: 12px;
    img {
        position: absolute;
        left: 10%;
        height: 25px;
        width: 25px;
    }
}