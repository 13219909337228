.tableau {
    width: 100%;
    border-collapse: collapse;
    border: none;
    border-style: hidden;
    th, td {
        padding: 8px;
        text-align: left;
        border: 1px solid #ddd;
    }
    td {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #454545;
    }
    tr {
        cursor: pointer;
        background-color: white;
        &:hover {
            background-color: #f9f9f9;
        }
    }
    .status-finalise {
      color: green;
    }
    .status-erreur {
      color: red;
    }
    .status-en-cours {
      color: purple;
    }
}