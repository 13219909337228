.add-team-form, .update-team-form {
    display: flex;
    flex-direction: column;
    h3 {
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        color: $color-primary;
    }
    .category {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        .field {
            display: flex;
            flex-direction: column;
            input {
                position: relative;
                width: calc(100% - 20px);
                height: 50px;
                margin: 20px 0px;
                padding-left: 20px;
                border: none;
                border-radius: 8px;
                background-color: #EFF1F999;
                font-size: 16px;
                &::placeholder {
                    position: absolute;
                    left: 20px;
                    color: #ABAFB1;
                }
            }
            .select {
                width: 100%;
                margin: 20px 0px;
            }
        }
        .basic-multi-select, .basic-single {
            .css-13cymwt-control, .select__control--is-focused {
                cursor: pointer;
                height: 50px;
                margin: 20px 0px;
                border: none;
                border-radius: 8px;
                background-color: #EFF1F999;
                font-size: 16px;
                .css-1fdsijx-ValueContainer {
                    padding: 2px 8px 2px 20px;
                }
            }
            .select__control .select__value-container {
                height: 100%;
                overflow: scroll;
                flex-wrap: nowrap;
                .css-1p3m7a8-multiValue {
                    min-width: unset;
                }
                .select__placeholder {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }
            .css-1nmdiq5-menu {
                top: 80%;
                .select__option {
                    cursor: pointer;
                }
            }
        }
    }
    .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        button {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 45%;
            height: 50px;
            border: none;
            border-radius: 12px;
            margin: 40px 0px;
        }
        .cancel-btn {
            background-color: white;
            color: $color-primary;
            border: solid 1.5px $color-primary;
        }
        .added-btn {
            background-color: $color-primary;
            color: white;
        }
    }
}
.update-team-form {
    button {
        background-color: $color-primary;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        border: none;
        border-radius: 12px;
        margin: 40px 0px;
    }
}