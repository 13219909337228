.WORKFLOW-TRANSCRIPTION {
    display: flex;
    flex-direction: column;
    margin: auto;
    .actions {
        display: flex;
        justify-content: end;
        button {
            margin: 20px 0px;
        }
        .save {
            color: #C30F0E;
            background-color: #ffffff;
            border: solid 1px #C30F0E;
        }
    }
}