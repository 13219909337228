.neurowide-dashboard-modal {
    z-index: 99;
    position: absolute;
    top: 0%;
    right: 0%;
    display: flex;
    flex-direction: column;
    width: 0%;
    max-width: 250px;
    height: 100vh;
    background-color: white;
    overflow: scroll;
    .close {
        cursor: pointer;
        position: absolute;
        top: 20px;
        right: 45px;
        width: 18px;
        height: 18px;
    }
}
.neurowide-dashboard-modal.open {
    transition: width 0.2s ease-in-out;
    width: 25%;
    padding: 20px 45px;
}