.lexicons-modal-form {
    display: flex;
    flex-direction: column;
    margin: 2.5% auto auto auto;
    width: 90%;
    .actions {
        display: flex;
        justify-content: center;
        button {
            cursor: pointer;
            width: 35%;
            height: 50px;
            margin: auto 20px;
            border: none;
            border-radius: 12px;
        }
        .cancel-btn {
            color: $color-primary;
            background-color: $color-secondary;
        }
        .append-btn {
            color: white;
            background-color: $color-primary;
        }
    }
}