.WORKFLOW-TRANSCRIPTION, .WORKFLOW-TRANSLATION {
    .actions button {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 10% 20px auto;
        min-width: 225px;
        min-height: 48px;
        color: white;
        background-color: $color-primary;
        border: none;
        border-radius: 12px;
    }
}