.progress-bar {
    position: relative;
    .line-1, .line-2 {
        transition: all 0.2s ease-in-out;
        position: absolute;
    }
    .line-1 {
        z-index: 2;
        height: 8px;
        background-color: $color-primary;
        border-radius: 10px;
        .point {
            height: 15px;
            width: 15px;
            border-radius: 90%;
            background-color: $color-primary;
            margin-top: -3px;
            margin-left: calc(99% - 5px);
        }
    }
    .line-2 {
        z-index: 1;
        width: 100%;
        height: 8px;
        background-color: $color-tertiary;
        border-radius: 10px;
    }
    .state {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        color: #ABAFB1;
    }
    .color-primary {
        cursor: pointer;
        color: $color-primary;
    }
}