.HOME-CONTENT {
    position: relative;
    margin: 20px auto;
    h2 {
        color: $color-primary;
        font-family: "Archivo Black", sans-serif;
        font-weight: bolder;
        font-size: 24px;
        margin: 20px auto;
        @include level2 {
            margin: 40px auto;
        }
    }
}