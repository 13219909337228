.information-panel {
    display: flex;
    width: 100%;
    margin: 20px auto;
    .information {
        background-color: white;
        border-radius: 10px;
        padding: 10px 20px;
        margin: auto 20px auto 0px;
        span {
            color: $color-primary;
        }
        p {
            margin: unset;
        }
    }
}