.screen-limit-overlay , .popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.643);
    backdrop-filter: blur(4px);
    @include level1 {
        display: none;
    }
    h2 {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding: 20px;
        color: #fff;
        text-align: center;
    }
}
.screen-limit-overlay {
    z-index: 99;
}
.popup-overlay {
    z-index: 98;
}
.popup-overlay.force-display {
    display: flex!important;
}