.WORKFLOW-LEXICON-01 {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 90%;
    @include level2 {
        width: 80%;
    }
    .workflow-lexicon-content {
        background-color: white;
        margin-top: 20px;
        margin-bottom: 35px;
        padding: 20px;
        border-radius: 10px;
        .visual {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            width: 140px;
            height: 140px;
            background-color: $color-secondary;
            border: solid 1px #BBC5CB;
            border-radius: 90%;
            img {
                width: 44px;
                height: 44px;
            }
        }
        h2 {
            text-align: center;
            font-family: "Archivo Black", sans-serif;
            font-size: 22px;
            color: $color-primary;
        }
        p {
            text-align: center;
        }
        .actions {
            display: flex;
            justify-content: center;
        }
    }
}