.select-lexicon-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 48px;
    min-width: 225px;
    min-height: 48px;
    margin: 10px;
    color: $color-primary;
    background-color: $color-secondary;
    border: none;
    border-radius: 12px;
}