.help-accordion {
    width: 100%;
    min-height: 40px;
    background-color: white;
    border-radius: 10px;
    margin: 20px auto 0px auto;
    .header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: auto;
        border-radius: 10px;
        transition: background-color 0.2s ease-in-out;
        &:hover {
            background-color: rgba(94, 99, 102, 0.1019607843);
        }
        h3 {
            margin-left: 20px;
        }
        img {
            margin-right: 20px;
        }
        .rotate {
            transform: rotate(90deg);
        }
    }
    .content {
        display: flex;
        transition: max-height 0.2s ease-in-out;
        overflow: hidden;
        p {
            color: #454545;
            margin: auto 0px;
            padding: 20px;
        }
    }
}