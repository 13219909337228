.PROJECT-DASHBOARD-HEADER {
    position: relative;
    margin: 20px auto 15px auto;
    span {
        position: relative;
        display: flex;
        width: fit-content;
        h1 {
            font-size: 30px;
            font-family: "Archivo Black", sans-serif;
            color: #C30F0E;
            margin-right: 20px;
        }
    }
    .previous-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        cursor: pointer;
        display: flex;
        width: 40px;
        height: 40px;
        margin: auto 2.5px;
        border: solid 1px $color-primary;
        border-radius: 90%;
        background-color: white;
        img {
            margin: auto;
        }
    }
}