.LEXICONS-CONTENT {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 90%;
    height: 60vh;
    border-radius: 10px;
    @include level2 {
        width: 80%;
    }
    .content {
        margin: auto;
        .visual {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            width: 140px;
            height: 140px;
            background-color: $color-secondary;
            border: solid 1px #BBC5CB;
            border-radius: 90%;
            img {
                width: 44px;
                height: 44px;
            }
        }
        h2 {
            text-align: center;
            font-family: "Archivo Black", sans-serif;
            font-size: 22px;
            color: $color-primary;
        }
    }
    .lexicon-list {
        display: flex;
        margin: unset;
        flex-direction: column;
        width: 45%;
        padding: unset;
    }
    .lexicon-edit {
        width: 45%;
        max-height: 50vh;
        padding: 10px 20px;
        background-color: white;
        border-radius: 10px;
        @include level2 {
            padding: 20px 40px;
        }
        .word-list {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            padding: unset;
            .rti--input {
                width: auto;
            }
        }
    }
}
.LEXICONS-CONTENT.workflow {
    display: flex;
    flex-direction: column;
    background-color: white;
}