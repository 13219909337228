.lexicon-list-item {
    cursor: pointer;
    display: flex;
    .lexicon-card {
        position: relative;
        display: flex;
        width: 80%;
        height: 58px;
        margin: 10px 10px 10px 0px;
        background-color: white;
        border-radius: 8px;
        transition: background-color 0.2s;
        &:hover {
            background-color: rgba(94, 99, 102, 0.1019607843);
        }
        span {
            position: absolute;
            top: 5px;
            left: 10px;
            font-size: 12px;
            color: $color-primary;
        }
        p {
            max-width: 275px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            margin: 25px auto auto 10px;
            font-size: 16px;
            color: #5E6366;
        }
        .shield-icon {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 20px;
            height: 20qpx;
        }
    }
    .circle {
        cursor: pointer;
        display: flex;
        margin: auto 10px;
        background-color: white;
        width: 34px;
        height: 34px;
        border-radius: 90%;
        box-shadow: 0px 0px 5px 0px #C30F0E33;
        img {
            margin: auto;
        }
    }
}