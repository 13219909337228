.NEUROWIDE-DASHBOARD-CONTENT {
    position: relative;
    margin: 40px auto 15px auto;
    h2 {
        display: flex;
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        color: $color-primary;
        img {
            margin: auto 5px;
        }
    }
    .add-team-btn {
        cursor: pointer;
        position: absolute;
        top: 0%;
        right: 0%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20%;
        height: 50px;
        color: white;
        background-color: $color-primary;
        border: none;
        border-radius: 12px;
        img {
            position: absolute;
            left: 10%;
            height: 25px;
            width: 25px;
        }
    }
    .teams {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}