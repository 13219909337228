.team-card {
    cursor: pointer;
    display: flex;
    width: 48%;
    height: 58px;
    margin: 10px 0px;
    color: black;
    background-color: white;
    border-radius: 8px;
    align-items: center;
    text-decoration: none;
    img {
        width: 20px;
        height: 20px;
        margin: auto 10px;
    }
    p {
        font-size: 16px;
    }
}