.TEAM-DASHBOARD-FOOTER {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    .left-content, .right-content {
        fill: black;
        cursor: pointer;
    }
    .left-content {
        display: flex;
        height: fit-content;
        select {
            width: 50px;
            height: 24px;
            padding: 0px 20px 0px 10px;
            border: none;
            border-radius: 8px;
            background-color: rgba(239, 241, 249, 0.6);
            font-size: 14px;
            cursor: pointer;
            appearance: none;
            background-image: url("../../assets/icon/arrow-down-black.svg");
            background-repeat: no-repeat;
            background-position: right 0px top 1.5px;
            background-size: 24px auto;
        }
        p {
            margin: auto 10px;
        }
    }
}