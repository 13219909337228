.not-valid {
    border: solid 1px red!important;
}
.team-form {
    .select {
        margin: 20px 0px;
    }
}
.lexicons-modal-form {
    .select {
        margin: 20px auto;
    }
}
.select {
    height: 50px;
    width: 80%;
    .css-13cymwt-control, .css-1fdsijx-ValueContainer {
        height: 50px;
    }
    .css-13cymwt-control {
        margin: unset;
    }
    .css-qbdosj-Input, .css-1hb7zxy-IndicatorsContainer {
        cursor: pointer;
    }
    .css-qbdosj-Input {
        margin: unset;
        padding: unset;
    }
}
.select-not-valid > .select__control {
    border: solid 1px red!important;
}