
.subplayer-subtitles {
    position: relative;
    box-shadow: 0px 5px 25px 5px rgb(0 0 0 / 80%);
    background-color: rgb(0 0 0 / 100%);
    .ReactVirtualized__Table {
        .ReactVirtualized__Table__Grid {
            outline: none;
        }
        .ReactVirtualized__Table__row {
            + .ReactVirtualized__Table__row .item {
                margin-top: 1px;
            }
            .item {
                position:relative;
                height: 100%;
                display:flex;
                flex-direction: row-reverse;
                align-items: center;
                transition: all 0.2s ease;
                background-color: rgba(255, 255, 255, 0.05);
                border: 1px solid rgba(255, 255, 255, 0.1);
                &.highlight {
                    background-color: rgb(0 87 158);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                }
                &.illegal {
                    background-color: rgb(123 29 0);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                }
                .textarea {
                    flex: 1 1 auto;
                    border: none;
                    box-sizing: border-box;
                    width: 100%;
                    color: #fff;
                    font-size: 12px;
                    font-family: Poppins;
                    padding:0 20px 0 0;
                    text-align: center;
                    resize: none;
                    outline: none;
                    background-color: transparent;
                }
                .buttons {
                    display: flex;
                    flex:0 0 30px;
                    flex-direction: column;
                    justify-content: center;
                    button {
                        border-radius: 0;
                        border: 1px solid rgba(255, 255, 255, 0.1);
                        margin:2px;
                        padding:0;
                        min-width: auto;
                        min-height: auto;
                        background-color: transparent;
                        img {
                            transform: scale(0.75);
                        }
                        &:disabled {
                            opacity:0.5;
                        }
                    }
                }
            }
        }
    }
}
