// global css
body {
    font-family: Poppins;
    font-size: 14px;
    background-color: $color-secondary;
    margin: unset;
    main.lg {
        margin-left: 6.5%;
        @include level2 {
            margin-left: 125px;
        }
    }
    main.xs {
        margin-left: 25%;
        @include level2 {
            margin-left: 315px;
        }
    }
    main.is-login {
        margin-left: 0%;
    }
    main.disconnect {
        transition: unset;
        background-image: url("../assets/img/wave-background.svg");
        background-size: cover;
        width: 100%;
    }
    main {
        transition: all 0.2s linear;
        background-position: center;
        width: auto;
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 76px);
        margin-top: 76px;
        .line {
            width: 80%;
            background-color: #BBC5CB;
            height: 0.5px;
            margin: 0px auto;
        }
        .line-w-80 {
            display: flex;
            width: 80%;
            height: 1px;
            margin: 20px auto;
            background-color: #BBC5CB;
        }
        .SECTION {
            width: 80%;
        }
        button {
            font-family: Poppins;
            font-size: 14px;
        }
    }
}
// views
.VIEW {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;
    min-height: calc(100vh - 175px);
    margin: 0px auto;
}
.AUTHENTIFICATION {
    min-height: 100vh!important;
}