.TEAM-MANAGEMENT-CONTENT {
    display: flex;
    flex-direction: column;
    margin: 20px auto;
    border-radius: 10px;
    @include level2 {
        width: 80%;
    }
    h2 {
        font-family: "Archivo Black", sans-serif;
        font-size: 24px;
        color: $color-primary;
    }
    .users {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        margin: 20px auto;
    }
}