.subplayer-timeline {
    z-index: 9;
    position: relative;
    width: 100%;
    height:25px;
    overflow-x: hidden;
    pointer-events: none;
    .sub-item {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-family: Poppins;
        cursor: move;
        user-select: none;
        pointer-events: all;
        background-color: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.2);
        &:hover {
            background-color: rgba(255, 255, 255, 0.3);
        }
        &.sub-highlight {
            background-color: rgba(33, 150, 243, 0.5);
            border: 1px solid rgba(33, 150, 243, 0.5);
        }
        &.sub-illegal {
            background-color: rgba(199, 81, 35, 0.5);
        }
        .sub-handle {
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            height: 100%;
            cursor: col-resize;
            user-select: none;
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
        .sub-text {
            position: relative;
            z-index: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            word-break: break-all;
            white-space: nowrap;
            text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                rgb(0 0 0) 0px -1px 1px;
            width: 100%;
            height: 100%;
            p {
                margin: 2px 0;
                line-height: 1;
                &.bilingual {
                    transform: scale(0.8);
                }
                &:first-child:not(:last-child):after {
                    content: "…";
                }
                & + p {
                    display:none;
                }
            }
        }
        .sub-duration {
            display:none;
            opacity: 0.5;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            font-size: 12px;
        }
    }
}
