.WORKFLOW-RENDER {
    display: flex;
    flex-direction: column;
    margin: auto;
    .header {
        display: flex;
        height: 50px;
        background-color: white;
        border-radius: 10px 10px 0px 0px;
        .header-content {
            display: flex;
            margin: auto;
            height: 50px;
            p {
                margin: auto 20px auto auto;
            }
            .basic-single {
                margin: auto;
                .css-13cymwt-control, .select__control--is-focused {
                    cursor: pointer;
                    position: relative;
                    height: 30px;
                    width: 150px;
                    margin: auto;
                    padding: 0px 10px;
                    border: none;
                    border-radius: 8px;
                    background-color: $color-secondary;
                    font-size: 14px;
                    .css-1dimb5e-singleValue {
                        color: $color-primary;
                    }
                }
                .css-1nmdiq5-menu {
                    z-index: 11;
                    width: 150px;
                    .select__option {
                        cursor: pointer;
                        color: $color-primary;
                        &:hover, &:focus, &:active {
                            background-color: $color-secondary;
                        }
                    }
                    .select__option--is-focused {
                        background-color: $color-secondary;
                    }
                    .select__option--is-selected {
                        background-color: $color-secondary;
                    }
                }
            }
        }
    }
    video {
        width: auto;
        height: 50vh;
        border-radius: 0px 0px 5px 5px;
        background-color: black;
    }
    .export-enable, .export-disable {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px 20px auto;
        width: 225px;
        height: 48px;
        color: white;
        border: none;
        border-radius: 12px;
        text-decoration: none;
    }
    .export-enable {
        cursor: pointer;
        background-color: #C30F0E;
    }
    .export-disable {
        cursor: not-allowed;
        background-color: #ABAFB1;
    }
}