.WORKFLOW-HEADER {
    position: relative;
    margin: 20px auto 15px auto;
    h1 {
        margin: unset;
        max-width: 90%;
    }
    nav {
        position: absolute;
        top: 0px;
        right: 0px;
        display: flex;
        button {
            cursor: pointer;
            display: flex;
            width: 40px;
            height: 40px;
            margin: auto 2.5px;
            border: solid 1px $color-primary;
            border-radius: 90%;
            img {
                margin: auto;
            }
        }
        .previous-btn {
            background-color: white;
        }
        .next-btn {
            background-color: $color-tertiary;
        }
    }
}