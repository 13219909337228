@keyframes rotateAnimation {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(1turn);
    }
}
.loading-component {
    display: flex;
    width: 100%;
    min-height: 225px;
    margin: auto;
    img {
        animation: rotateAnimation linear 2s infinite;
        width: 50px;
        height: 50px;
        margin: auto;
    }
}