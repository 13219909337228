.PROFILE-CONTENT {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 2.5% auto;
    width: 90%;
    min-height: 55vh;
    max-height: 60vh;
    background-color: white;
    border-radius: 10px;
    @include level2 {
        width: 80%;
    }
    h2 {
        font-size: 16px;
        margin: 2.5% 0% 0% 5%;
    }
    .right-content {
        position: absolute;
        top: 10%;
        right: 10%;
        margin: auto;
        .profile-picture {
            width: 175px;
            height: 175px;
            border-radius: 90%;
            @include level2 {
                width: 200px;
                height: 200px;
            }
        }
        .actions {
            position: absolute;
            top: 70%;
            right: 27.5%;
            display: flex;
            div {
                cursor: pointer;
                display: flex;
                min-width: 34px;
                min-height: 34px;
                margin: 0px 5px;
                background-color: white;
                border-radius: 10px;
                img {
                    margin: auto;
                }
            }
        }
    }
}