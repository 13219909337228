.PROJECTS-TABS {
    display: flex;
    width: 80%;
    margin: 40px auto 15px auto;
    .tab {
        cursor: pointer;
        margin: auto 40px 10px 0px;
        font-size: 16px;
        color: #ABAFB1;
    }
    .active {
        color: $color-primary;
        font-weight: bold;
    }
}