header {
    z-index: 97;
    position: fixed;
    top: 0px;
    background-color: white;
    box-shadow: rgba(37, 39, 89, 0.08) 0px 8px 8px 0;
    width: 100%;
    height: 76px;
    display: flex;
    .nw-header-nav {
        margin: auto 40px auto auto;
        ul {
            position: relative;
            display: flex;
            list-style: none;
            margin: auto;
            .langs {
                transition: all 0.2s ease-in-out;
                position: absolute;
                left: -80px;
                display: flex;
                flex-direction: column;
                min-width: 90px;
                padding-left: 0px;
                border: solid 0.5px $color-tertiary;
                background-color: white;
                .lang {
                    margin-right: 50px;
                    .flag {
                        margin: auto 10px auto 0px;
                        width: 30px;
                        height: 30px;
                        border-radius: 90%;
                    }
                }
                .arrow {
                    cursor: pointer;
                    position: absolute;
                    top: 10.5px;
                    right: 15px;
                    width: 25px;
                    height: 25px;
                }
            }
            li {
                cursor: pointer;
                display: flex;
                margin: auto 10px;
                line-height: 44px;
                a {
                    display: flex;
                    .profile-picture {
                        width: 44px;
                        height: 44px;
                        border-radius: 90%;
                    }
                }
            }
        }
    }
}