.subplayer-player {
    display: flex;
    .video {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        video {
            width:100%;
            height:100%;
            object-fit:contain;
            max-height: calc(100% - 56px);
            margin:0 auto;
            z-index: 10;
            outline: none;
            cursor: pointer;
        }
        .icon {
            position: absolute;
            z-index: 11;
            top:50%;
            left:50%;
            transform:translate(-50%,-50%) scale(2);
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width:24px;
            height:24px;
            border-radius: 24px;
            background-color:#C30F0E;
            opacity:0;
            transition:opacity 0.5s linear;
            pointer-events:none;
            img {
                display:none;
            }
            &.pause {
                img:first-child { display:block; }
            }
            &.play {
                img:last-child { display:block; }
            }
            &.force, &.show {
                opacity:1;
                transition:opacity 0.2s linear;
            }
            &.fwd {
                left:85%;
            }
            &.rwd {
                left:15%;
            }
            &.fwd, &.rwd {
                img {
                    display:block;
                    width:12px;
                    height:12px;
                    margin-top:-4px;
                }
                span { 
                    position: absolute;
                    bottom:0;
                    font-size:6px;
                    color:white;
                }
            }
        }
        video:hover + .icon {
            opacity:1;
            transition:opacity 0.2s linear;
        }
        .subtitle {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 20;
            left: 0;
            right: 0;
            bottom: 0;
            width: 90%;
            margin: 28px auto;
            padding: 0 20px;
            user-select: none;
            pointer-events: none;
            overflow: hidden;
            .block {
                width:100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: rgb(0 0 0 / 0);
            }
            &.pause .block {
                background-color: rgb(0 0 0 / 50%);
            }
            .operate {
                padding: 5px 15px;
                color: #fff;
                font-size: 13px;
                border-radius: 3px;
                margin-bottom: 5px;
                background-color: rgb(0 0 0 / 75%);
                border: 1px solid rgb(255 255 255 / 20%);
                cursor: pointer;
                pointer-events: all;
            }
            .textarea {
                min-width: 510px;
                outline: none;
                resize: none;
                text-align: center;
                line-height: 1.2;
                border: none;
                color: #fff;
                font-size: 18px;
                font-family: Arial, sans-serif;
                padding: 5px 10px;
                user-select: all;
                pointer-events: all;
                text-shadow: rgb(0 0 0) 1px 0px 1px, rgb(0 0 0) 0px 1px 1px, rgb(0 0 0) -1px 0px 1px,
                    rgb(0 0 0) 0px -1px 1px;
                background-color: transparent;
            }
        }
    }
}
