.team-user-card {
    position: relative;
    display: flex;
    background-color: white;
    width: 49%;
    height: 60px;
    border-radius: 10px;
    margin: 1% 0px;
    .profile-picture {
        width: 28px;
        height: 28px;
        margin: auto 10px;
        border-radius: 90%;
    }
    p {
        margin: auto 10px;
        max-width: 60%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .team-manager-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 15px;
        right: 50px;
    }
    .trash-btn {
        cursor: pointer;
        position: absolute;
        width: 24px;
        height: 24px;
        top: 15px;
        right: 20px;
    }
}